/** @jsx jsx */

import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { MobileMenu } from './mobilemenu'
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

import { colors, variables } from '../styles/global'

interface Props {
  siteTitle: string
  hideMenu: boolean
}

const Header = ({ hideMenu }: Props) => {
  const [showMenu, setShowMenu] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const menuRef = useRef(false)

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        if (window.innerWidth > variables.menuBreakPoint) {
          setMobileMenu(false)
        }
        if (window.innerWidth < variables.menuBreakPoint) {
          setMobileMenu(true)
        }
      }
    }
    const handleScrollMenu = () => {
      if (window) {
        if (window.pageYOffset > 300) {
          if (!menuRef.current) {
            setShowMenu(true)
            menuRef.current = true
          }
        } else {
          if (menuRef.current) {
            setShowMenu(false)
            menuRef.current = false
          }
        }
      }
    }

    window.addEventListener('scroll', handleScrollMenu)
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('scroll', handleScrollMenu)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <header css={css``}>
      <div
        css={css`
          margin: 0 auto;
          width: 100%;
          color: ${colors.white};
          font-family: Oswald;
          font-weight: 400;
          font-size: 1.6rem;
          text-transform: uppercase;
          z-index: 10;
          position: fixed;
          left: 0;
          top: 0;
        `}
      >
        {mobileMenu ? (
          <MobileMenu />
        ) : (
          <nav
            css={css`
              ${variables.mobile} {
                display: none;
              }
              background-color: ${colors.black};
              position: absolute;
              left: 0;
              width: 100%;
              display: flex;

              padding: 4px 0 4px 0;
              margin-right: 10%;
              border-bottom: 2px solid ${colors.lightBlue};
              transition: top 0.4s;
            `}
            className={showMenu ? 'down' : hideMenu ? 'up' : 'down'}
          >
            <div
              css={css`
                display: inline-flex;
                width: 7rem;
                margin-left: 20px;
                justify-content: space-between;
              `}
            >
              <a
                href="mailto: frits@vikingentertainment.nl"
                className="hoverScale"
                css={css`
                  margin-bottom: 9px;
                `}
              >
                <FiMail color={colors.white} className="react-icons" />
              </a>
              <a
                href="https://www.instagram.com/first_class_flamingos/"
                className="hoverScale"
                css={css`
                  margin-bottom: 9px;
                `}
              >
                <FaInstagram color={colors.white} className="react-icons" />
              </a>
              <a
                href="https://www.facebook.com/firstclassflamingos"
                className="hoverScale"
                css={css`
                  margin-bottom: 9px;
                `}
              >
                <FaFacebookSquare
                  color={colors.white}
                  className="react-icons"
                />
              </a>
            </div>
            <Link to="/" className="navLink">
              Home
              <div className="menuLine" />
            </Link>
            <a
              css={css`
                &:hover {
                  cursor: pointer;
                }
              `}
              className="navLink"
              onClick={(e) => {
                e.preventDefault()
                window.scrollTo({
                  left: 0,
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                })
              }}
            >
              Contact
              <div className="menuLine" />
            </a>
            <Link to="/contact" className="navLink">
              Meet & Greet
              <div className="menuLine" />
            </Link>
            <Link to="/klantenvertellen" className="navLink">
              Klanten Vertellen
              <div className="menuLine" />
            </Link>
            <Link to="/repertoire" className="navLink">
              Repertoirelijst
              <div className="menuLine" />
            </Link>
          </nav>
        )}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
