/** @jsx jsx */

import { HiMenu, HiMenuAlt2 } from 'react-icons/hi'
import { Link } from 'gatsby'

import { IoMdClose } from 'react-icons/io'
import { globalCss, variables, colors } from '../styles/global'
import { Global, css, jsx } from '@emotion/react'
import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

export const MobileMenu = () => {
  const [open, setOpen] = useState(false)

  const styles = useSpring({
    top: open ? '0px' : '-600px',
  })

  return (
    <div>
      <animated.div
        style={styles}
        css={css`
          position: fixed;
          background-color: ${colors.black};
          width: 100%;
          border-bottom: 2px solid ${colors.pink};
          z-index: 5;
        `}
      >
        <nav
          css={css`
            display: flex;
            padding: 20px 0 20px 10%;

            flex-direction: column;
            justify-content: space-around;
          `}
        >
          <Link to="/" className="navLinkMobile">
            Home
            <div className="menuLineMobile" />
          </Link>
          <a
            className="navLinkMobile"
            onClick={(e) => {
              e.preventDefault()
              setOpen(false)
              window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
              })
            }}
          >
            Contact
            <div className="menuLineMobile" />
          </a>
          <Link to="/contact" className="navLinkMobile">
            Meet & Greet
            <div className="menuLineMobile" />
          </Link>
          <Link to="/klantenvertellen" className="navLinkMobile">
            Klanten Vertellen
            <div className="menuLineMobile" />
          </Link>
          <Link to="/repertoire" className="navLinkMobile">
            Repertoirelijst
            <div className="menuLineMobile" />
          </Link>
          <div
            css={css`
              display: inline-flex;
              width: 7rem;
              margin-left: 20px;
              justify-content: space-between;
            `}
          >
            <a
              href="mailto: frits@vikingentertainment.nl"
              css={css`
                margin-bottom: 9px;
                transition: transform 0.3s;
                &:hover {
                  transform: scale(1.2);
                }
              `}
            >
              <FiMail color={colors.white} className="react-icons" />
            </a>
            <a
              href="https://www.instagram.com/first_class_flamingos/"
              css={css`
                margin-bottom: 9px;
                transition: transform 0.3s;
                &:hover {
                  transform: scale(1.2);
                }
              `}
            >
              <FaInstagram color={colors.white} className="react-icons" />
            </a>
            <a
              href="https://www.facebook.com/firstclassflamingos"
              css={css`
                margin-bottom: 9px;
                transition: transform 0.3s;
                &:hover {
                  transform: scale(1.2);
                }
              `}
            >
              <FaFacebookSquare color={colors.white} className="react-icons" />
            </a>
          </div>
        </nav>
      </animated.div>
      <div
        css={css`
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 10;

          &:hover {
            cursor: pointer;
          }
        `}
        role="button"
        onClick={() => setOpen(!open)}
      >
        {!open ? (
          <HiMenuAlt2
            color={colors.white}
            css={css`
              width: 45px;
              height: 45px;
            `}
          />
        ) : (
          <IoMdClose
            color={colors.white}
            css={css`
              width: 45px;
              height: 45px;
            `}
          />
        )}
      </div>
    </div>
  )
}
